<template>
  <div
    v-if="title || description || sliderButtons.enabled"
    class="section-header"
    :class="[
      {
        'is-centered': props.centered,
        'is-wide': props.wide,
        'has-min-height': props.hasMinHeight,
        'has-slider-buttons': props.sliderButtons.enabled,
        'is-1-slide': props.sliderButtons.enabled && props.sliderButtons.items.length <= 1,
        'is-2-slide': props.sliderButtons.enabled && props.sliderButtons.items.length === 2,
      },
      props.width ? `is-${props.width}` : '',
    ]"
  >
    <component :is="props.titleTag" v-if="props.title" class="section-header__title title" :class="[`is-${props.titleSize}`]">
      {{ props.title }}
    </component>
    <bard v-if="props.description" :as-content="true" :content="props.description" class="section-header__description lead-label" />
    <pt-slider-btn
      v-if="props.sliderButtons.enabled"
      side-to="left"
      theme="blue"
      :class="['section-header__slider-button is-prev', props.sliderButtons.prevEl]"
    />
    <pt-slider-btn
      v-if="props.sliderButtons.enabled"
      side-to="right"
      theme="blue"
      :class="['section-header__slider-button is-next', props.sliderButtons.nextEl]"
    />
  </div>
</template>

<script setup lang="ts">
import Bard from '~/components/content/Bard.vue';
import PtSliderBtn from '~/components/global/elements/PtSliderBtn.vue';

const props = defineProps({
  centered: {
    type: Boolean,
    default: true,
  },
  wide: {
    type: Boolean,
    default: false,
  },
  hasMinHeight: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  titleTag: {
    type: String,
    default: 'h2',
  },
  titleSize: {
    type: String,
    default: '2',
  },
  description: {
    type: [String, Array],
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  sliderButtons: {
    type: Object,
    default: () => ({ enabled: false, items: [] }),
  },
});
</script>
