<template>
  <button ref="el" class="pt-slider-btn" :class="rootClasses">
    <pt-icon-chevron-right />
  </button>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import PtIconChevronRight from '@/components/global/icons/PtIconChevronRight.vue';

const el = ref(null);
const props = defineProps({
  sideTo: {
    type: String,
    default: 'right',
  },
  theme: {
    type: String,
    default: '',
  },
});

const rootClasses = computed(() => {
  const classes = [];
  if (props.theme) {
    classes.push(`is-${props.theme}`);
  }
  if (props.sideTo) {
    classes.push(`is-${props.sideTo}`);
  }
  return classes;
});

defineExpose({
  el,
});
</script>
