<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__items">
      <li v-if="includeHome" class="breadcrumbs__item">
        <NuxtLink to="/" class="breadcrumbs__link" aria-label="Public Trust Home">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.7858 5.08301L10.8102 0.597268C9.71481 -0.225054 8.20992 -0.195548 7.1467 0.669104L1.12227 5.5684C0.412788 6.14537 0 7.01739 0 7.93921V16.9601C0 18.639 1.34315 20 3 20L5 20H7H11H13L15 20C16.6569 20 18 18.639 18 16.9601V7.52566C18 6.56251 17.5496 5.65634 16.7858 5.08301ZM13 17.9734H15C15.5523 17.9734 16 17.5197 16 16.9601V7.52566C16 7.20461 15.8499 6.90255 15.5953 6.71144L9.6197 2.2257C9.25456 1.95159 8.75293 1.96143 8.39852 2.24965L2.37409 7.14894C2.1376 7.34126 2 7.63194 2 7.93921V16.9601C2 17.5197 2.44772 17.9734 3 17.9734H5V14.9335C5 12.6949 6.79086 10.8803 9 10.8803C11.2091 10.8803 13 12.6949 13 14.9335V17.9734ZM7 17.9734H11V14.9335C11 13.8142 10.1046 12.9069 9 12.9069C7.89543 12.9069 7 13.8142 7 14.9335V17.9734Z"
              fill="#28294E"
            />
          </svg>
        </NuxtLink>
      </li>
      <li v-for="(item, index) in realItems" :key="`item-${index}-${item.to}-${item.id}`" class="breadcrumbs__item">
        <NuxtLink :to="slashed(item.to)" class="breadcrumbs__link">
          {{ item.label }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { slashed } from '~/utils/url';
import { usePagesStore } from '~/stores/pages';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  includeHome: {
    type: Boolean,
    default: true,
  },
  auto: {
    type: Boolean,
    default: true,
  },
});

const pages = usePagesStore();
const realItems = computed(() => {
  return props.auto ? pages.currentBreadcrumbs : props.items;
});
</script>
